import { render, staticRenderFns } from "./invest_index.vue?vue&type=template&id=a2bc4c48&scoped=true&"
import script from "./invest_index.vue?vue&type=script&lang=js&"
export * from "./invest_index.vue?vue&type=script&lang=js&"
import style0 from "./invest_index.vue?vue&type=style&index=0&id=a2bc4c48&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a2bc4c48",
  null
  
)

export default component.exports